<template>
  <section class="text-center bg-body-tertiary min-vh-100 d-flex align-items-center">
    <div class="container">
      <h2 class="text-muted fade-in1">
        {{$t("main.home.hello")}}
      </h2>
      <h1 class = "display-1 fade-in2">
        {{$t("main.home.imGianluca")}}
      </h1>
        <ScrollDown class="scrolldown"/>
    </div>
  </section>

  <div class="container">
    <div class="row">
      <div v-for="card in pages" :key="card.title" class="small-card col-md-6 col-lg-4">
        <router-link :to="'/' + $i18n.locale + card.link" class="nav-item nav-link">
          <div class="card h-100 scroll-appear">
            <div class="card-body">
              <div class="row">
                <div class="col d-flex flex-column">
                  <p class="h1">{{ $t(card.title) }}</p>
                </div>
                <div class="col-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                  </svg>
                </div>
                <!-- <p class="fw-normal">{{ $t(card.description)}}</p> -->
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import ScrollDown from "@/components/ScrollDown.vue";

function scrollAppear() {
  var cards = document.querySelectorAll('.scroll-appear');

  cards.forEach(function(card) {
    var cardPosition = card.getBoundingClientRect().top;
    var screenPosition = window.innerHeight;

    if (cardPosition < screenPosition) {
      card.classList.add('scroll-appear--visible');
    }
  });
}

window.addEventListener('scroll', scrollAppear);


const pages = ref([
  {
    id: 0,
    title: "main.nav.about",
    description: "main.home.about.description",
    link: "/about"
  },
  {
    id: 1,
    title: "main.nav.projects",
    description: "main.home.portfolio.description",
    link: "/portfolio"
  },
  {
    id: 2,
    title: "main.nav.contacts",
    description: "main.home.contacts.description",
    link: "/contacts"
  }
])

</script>

<script>

export default {
  name: 'HomeView'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes fadeIn {
  0%   {opacity:0}
  100% {opacity:1}
}

.text-muted {
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.display-1 {
  opacity: 0;
  animation-name: fadeIn;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.card {
  border-radius: 16px;
  border-style: none;
  margin-bottom: 20px;
}

.card:hover {
  transform: scale(1.025);
}

.small-card {
  margin-bottom: 20px;
}

.scroll-appear {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.scroll-appear--visible {
  opacity: 1;
  transform: translateY(0);
}

</style>
