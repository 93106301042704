<template>
    <div class="scrolldown">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-compact-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"/>
          </svg>
        </span>
    </div>
</template>

<script>
export default {
    name: "ScrollDown"
}
</script>

<style scoped>
.scrolldown {
    opacity: 0;
    animation-name: fadeIn;
    animation-delay: 1.5s;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 50px;
    height: 40px;
    transform: translateY(-20px) translateX(-50%);
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 0.3; }
}

.scrolldown span {
    position: absolute;
    display: block;
    animation: animate 3s linear infinite;
    opacity: 0;
}

.scrolldown span:nth-child(1) {
    transform: translate(0px, 15px);
    animation-delay: 0s;
}

@keyframes animate {
  0% {
    top:-5px;
    opacity: 0;
  }
  25% {
    top:0;
    left:0;
    opacity: 0.5;
  }
  50% {
    top:0;
    left:0;
    opacity: 1;
  }
  75% {
    top:0;
    left:0;
    opacity: 1;
  }
  90% {
    top:0;
    left:0;
    opacity: 0.25;
  }
  100% {
    top:0;
    left:0;
    opacity: 0;
  }
}
</style>