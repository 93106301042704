<template>

  <div class="p-5 bg-body-tertiary"></div>

  <section class="p-5 text-center flex-grow-1 bg-body-tertiary">
    <div class="container">
      <h1 class="display-1">
        {{ $t("main.about.aboutMe") }}
      </h1>
    </div>
  </section>
  <section class="p-5 flex-grow-1 bg-body-tertiary">
    <div class="container">
      <h2>
        {{$t("main.about.description")}}
      </h2>
    </div>
  </section>
  <!-- Section: Timeline -->
  <section class="bg-light p-5 flex-grow-1 bg-body-tertiary">
    <ul class="timeline-with-icons">

      <li class="timeline-item mb-5" v-for="event in events" :key="event.id">
            <span class="timeline-icon">
              <i class="text-primary fa-sm fa-fw"></i>
            </span>
            <h4 class="fw-bold">{{$t(event.name)}}</h4>
            <h5 class="text-muted mb-1 fw-bold">{{$t(event.location)}}</h5>
            <h6 class="text-muted mb-3 fw-bold">{{$t(event.date)}}</h6>
            <p class="text-muted mb-5">
              {{$t(event.description)}}
            </p>
      </li>
    </ul>
  </section>
</template>

<script>

export default {
  name: 'AboutMe'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.timeline-with-icons {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: hsl(0, 0%, 91%);
  color: hsla(217, 89%, 35%, 0.99);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script setup>
import {ref} from "vue";
const events = ref([{
  id: 0,
  name: "main.about.iriscube.name",
  location: "main.about.iriscube.location",
  date: "main.about.iriscube.date",
  description: "main.about.iriscube.description"
}, {
  id: 1,
  name: "main.about.msc.name",
  location: "main.about.msc.location",
  date: "main.about.msc.date",
  description: "main.about.msc.description"
}, {
  id: 2,
  name: "main.about.bsc.name",
  location: "main.about.bsc.location",
  date: "main.about.bsc.date",
  description: "main.about.bsc.description"
}, {
  id: 3,
  name: "main.about.dip.name",
  location: "main.about.dip.location",
  date: "main.about.dip.date",
  description: "main.about.dip.description"
}])
</script>
