<template>

  <div class="p-5 bg-body-tertiary spacer" style="display: none" />
  <section class="text-center bg-body-tertiary d-flex align-items-center my-element">
    <div class="container">
      <h1 class="display-1">
        {{ $t("main.projects.myProjects") }}
      </h1>
      <h4 class="text-muted">
        {{ $t("main.projects.introduction") }}
      </h4>
    </div>
      <ScrollDown/>
  </section>
  <div class="p-4 bg-body-tertiary spacer" style="display: none"/>

    <div class="container" v-for="card in projects.filter(card => card.images.length>0)" :key="card.title">
      <div class="card h-100 scroll-appear">
        <div class="row d-flex-sm flex-row-sm no-gutters">
          <div class="col-md-6 col-lg-8">
            <div v-bind:id="'carousel' + card.id" class="carousel slide h-100" data-bs-ride="true">
              <div class="carousel-inner h-100">
                <div class="carousel-item h-100" :class="{ active: id===0 }" v-for="(image,id) in card.images.sort(function(a, b){return a.id-b.id})" :key="image.id">
                  <img :src="require(`@/assets/projects/${image.src}`)" class="d-block w-100 h-100" alt="...">
                </div>
              </div>
              <div v-if="card.images.length > 1">
                <button class="carousel-control-prev" type="button" :data-bs-target="'#carousel' + card.id" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" :data-bs-target="'#carousel' + card.id" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 d-flex flex-column">
            <div class="card-body text-start">
              <div>
                  <span class="p-1" v-for="tag in card.tags" :key="tag">
                    <div class="badge rounded-pill text-bg-light g-2" >{{ $t(tag) }}</div>
                  </span>
              </div>
              <p class="h1">{{ $t(card.title, ) }}</p>
              <p class="fw-normal">{{ $t(card.description)}}</p>

            </div>

            <div v-if="card.links.length > 0" class = "d-flex align-items-end">
              <div class="flex-grow-1"></div>
              <div class="button-group">
                <a
                    class="icon btn text-white btn-floating m-1"
                    style="background-color: #333333;"
                    :href="link.url"
                    role="button"
                    v-for="link in card.links.filter(link => link.type==='github')"
                    :key="link.url"
                    target="_blank" rel="external noopener noreferrer"
                ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
                  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                </svg></a>
                <a
                    class="icon btn text-white btn-floating m-1"
                    style="background-color: #2353d6;"
                    :href="link.url"
                    role="button"
                    v-for="link in card.links.filter(link => link.type==='web')"
                    :key="link.url"
                    target="_blank" rel="external noopener noreferrer"
                ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
                </svg></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div v-for="card in projects.filter(card => card.images.length===0)" :key="card.title" class="small-card col-md-6 col-lg-4">
          <div class="card h-100 scroll-appear">
            <div class="card-body text-start">
              <div>
              <span class="p-1" v-for="tag in card.tags" :key="tag">
                <div class="badge rounded-pill text-bg-light g-2" >{{ $t(tag) }}</div>
              </span>
              </div>
              <p class="h1">{{ $t(card.title, ) }}</p>
              <p class="fw-normal">{{ $t(card.description)}}</p>

            </div>

            <div v-if="card.links.length > 0" class = "d-flex align-items-end">
              <div class="flex-grow-1"></div>
              <div class="button-group">
                <div
                    class="icon btn text-white btn-floating m-1"
                    style="background-color: #333333;"
                    :href="link.url"
                    role="button"
                    v-for="link in card.links.filter(link => link.type==='github')"
                    :key="link.url"
                    target="_blank" rel="external noopener noreferrer"
                ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
                  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                </svg></div>
                <div
                    class="icon btn text-white btn-floating m-1"
                    style="background-color: #2353d6;"
                    :href="link.url"
                    role="button"
                    v-for="link in card.links.filter(link => link.type==='web')"
                    :key="link.url"
                    target="_blank" rel="external noopener noreferrer"
                ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
                </svg></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import ScrollDown from "@/components/ScrollDown.vue";

function scrollAppear() {
  const cards = document.querySelectorAll('.scroll-appear');

  cards.forEach(function(card) {
    const cardPosition = card.getBoundingClientRect().top;
    const screenPosition = window.innerHeight / 1.5;

    if (cardPosition < screenPosition) {
      card.classList.add('scroll-appear--visible');
    }
  });
}

window.addEventListener('scroll', scrollAppear);

/*
function reduceElementSize() {
  const spacers = document.querySelector('.spacer');
  spacers.style.display = "block";
  var element = document.querySelector('.my-element');
  var elementHeight = element.offsetHeight;

  // Calculate the new height based on the user's scroll position
  var newHeight = Math.max(250, elementHeight - window.scrollY);

  // Set the new height of the element
  element.style.height = newHeight + 'px';
}

window.addEventListener('scroll', reduceElementSize);

 */
const projects = ref([{
  id: 0,
  title: "main.projects.3DConnect4.title",
  description: "main.projects.3DConnect4.description",
  images: [ {
    src: "3DConnect4/3DC4.1.png",
    id: 1
  },
    {
      src: "3DConnect4/3DC4.2.png",
      id: 2
    },
    {
      src: "3DConnect4/3DC4.3.png",
      id: 3
    }
  ],
  links: [
    {url: "https://github.com/GianlucaBergamini/3DConnect4",
      type: "github"},
    {url: "https://gianlucabergamini.github.io/3DConnect4/3DConnect4.html",
      type: "web"}
  ],
  tags: ["main.projects.tags.webgl", "main.projects.tags.javascript"]
},
  {
    id: 1,
    title: "main.projects.PricesTracker.title",
    description: "main.projects.PricesTracker.description",
    images: [
      {
        src: "PricesTracker/PricesTracker.1.png",
        id: 2
      },
      {
        src: "PricesTracker/PricesTracker.2.png",
        id: 1
      },
      {
        src: "PricesTracker/PricesTracker.4.png",
        id: 3
      }


    ],
    links: [],
    tags: ["main.projects.tags.swift", "main.projects.tags.swiftui", "main.projects.tags.ios", "main.projects.tags.ipados", "main.projects.tags.watchos", ]
  },
  {
    id: 2,
    title: "main.projects.Santorini.title",
    description: "main.projects.Santorini.description",
    images: [ {
      src: "Santorini/Santorini.1.png",
      id: 6
    },
      {
        src: "Santorini/Santorini.2.png",
        id: 5
      },
      {
        src: "Santorini/Santorini.3.png",
        id: 4
      },
      {
        src: "Santorini/Santorini.4.png",
        id: 2
      },
      {
        src: "Santorini/Santorini.5.png",
        id: 3
      },
      {
        src: "Santorini/Santorini.6.png",
        id: 1
      },

    ],
    links: [],
    tags: ["main.projects.tags.java", "main.projects.tags.javafx"]
  },
  {
    id: 3,
    title: "main.projects.API.title",
    description: "main.projects.API.description",
    images: [
    ],
    links: [],
    tags: ["main.projects.tags.c"]
  },
  {
    id: 4,
    title: "main.projects.workingZones.title",
    description: "main.projects.workingZones.description",
    images: [
    ],
    links: [
    ],
    tags: ["main.projects.tags.vhdl"]
  },
  {
    id: 5,
    title: "main.projects.IoT.title",
    description: "main.projects.IoT.description",
    images: [
    ],
    links: [
    ],
    tags: ["main.projects.tags.nodered", "main.projects.tags.tinyos"]
  },
  {
    id: 6,
    title: "main.projects.middleware.title",
    description: "main.projects.middleware.description",
    images: [
    ],
    links: [
    ],
    tags: ["main.projects.tags.kafka", "main.projects.tags.akka", "main.projects.tags.nodered", "main.projects.tags.spark", "main.projects.tags.contiki", "main.projects.tags.mpi"]
  },
  {
    id: 7,
    title: "main.projects.tiw.title",
    description: "main.projects.tiw.description",
    images: [
    ],
    links: [
    ],
    tags: ["main.projects.tags.javaservlet",  "main.projects.tags.javascript", "main.projects.tags.thymeleaf", "main.projects.tags.html", "main.projects.tags.css"]
  },
  {
    id: 8,
    title: "main.projects.robotics.title",
    description: "main.projects.robotics.description",
    images: [
    ],
    links: [
    ],
    tags: ["main.projects.tags.ros",  "main.projects.tags.c++", "main.projects.tags.python"]
  }
])
</script>

<script>
export default {
  name: "MyProjects"
}
</script>

<style scoped>

@media (prefers-color-scheme: dark) {
  img {
    opacity: 80%;
  }
}

.card {
  border-radius: 16px;
  border-style: none;
  margin-bottom: 20px;
}

.card:hover {
  transform: scale(1.025);
}

.carousel-control-next-icon {
  opacity: 0;
  transition-duration: 1s;
}
.carousel-control-next-icon:hover {
  transition-duration: 1s;
  opacity: 100%;
}

.carousel-control-prev-icon {
  opacity: 0;
  transition-duration: 1s;
}
.carousel-control-prev-icon:hover {
  transition-duration: 1s;
  opacity: 100%;
}

.small-card {
  margin-bottom: 20px;
}

.carousel-item {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.carousel-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  border-radius: 16px;
}

.icon {
  margin-right: 10px !important;
  margin-bottom: 5px !important;
}

.button-group {
  margin-right: 20px !important;
  margin-bottom: 20px !important;
}

.h1 {
  margin-top: 30px !important;
  word-wrap: break-word !important;
}

.scroll-appear {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.scroll-appear--visible {
  opacity: 1;
  transform: translateY(0);
}
.my-element {
  height: 100vh;
  transition: height 0.5s ease;
}
</style>