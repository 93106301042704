<template>
  <div class="min-vh-100 d-flex flex-column bg-body-tertiary">
    <nav class="navbar navbar-expand-sm fixed-top">
      <div class="container-fluid">
        <router-link :to="'/' + $i18n.locale + '/'" class="pl-3 navbar-brand fw-semibold">
          <div id="container" style="white-space:nowrap">
            <div id="image" style="display:inline;">
              <img :src="require(`@/assets/logo/Logo.png`)" alt="" style="width:2em; display:inline; padding-bottom: 5px">
            </div>
            <div id="texts" style="display:inline; white-space:nowrap;">
              Gianluca Bergamini
            </div>
          </div>
        </router-link>
        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="toggler-icon top-bar"></span>
          <span class="toggler-icon middle-bar"></span>
          <span class="toggler-icon bottom-bar"></span>
        </button>
        <div class="collapse navbar-collapse " id="navbarToggleExternalContent">
          <ul class="navbar-nav me-auto my-2 my-sm-0" style="--bs-scroll-height: 100px;">
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/about'" class="nav-item nav-link">{{ $t("main.nav.about") }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/portfolio'" class="nav-item nav-link">{{ $t("main.nav.projects") }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/' + $i18n.locale + '/contacts'" class="nav-item nav-link">{{ $t("main.nav.contacts") }}</router-link>
            </li>
          </ul>

          <div class="locale-changer">
            <select v-model="$i18n.locale" class="form-select" @change="changed($event)">
              <option v-for="(lang, i) in languages" :key="`Lang${i}`" :value="lang.sym">
                {{ lang.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </nav>

    <router-view/>

    <footer class="text-center">
      <div class="text-center p-3 bg-body-secondary">
        <div class="container p-1 pb-0">
          <section class="mb-4">
            <a
                class="btn text-white btn-floating m-1"
                style="background-color: #0082ca;"
                href="https://linkedin.com/in/gianluca-bergamini-93b0a6252"
                role="button"
            ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
            </svg></a>
            <a
                class="btn text-white btn-floating m-1"
                style="background-color: #333333;"
                href="https://github.com/GianlucaBergamini"
                role="button"
            ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
              <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
            </svg></a>
          </section>
        </div>
        Copyright &copy; {{ year }}: Gianluca Bergamini
      </div>
    </footer>
  </div>
</template>

<script setup>
import {ref} from 'vue'
const year = ref (new Date().getFullYear().toString())
</script>

<script>
import {supportedLanguages} from "@/main";
export default {
  name: 'App',
  data() {
    return {languages: supportedLanguages};
  }
}

function changed(event) {
  const target = event.target.value
  const source = window.location.pathname.split('/');
  const defTarget = '/' + target + '/'+ source.slice(2).join('/')
  window.open(defTarget, "_self")
  // window.history.replaceState('', '', defTarget);
}
</script>

<style>
body {
  background-color: rgb(233, 236, 239);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.navbar {
  background: rgba(248, 249, 250, 0.7) !important;
  z-index: 2;
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
}

@media (prefers-color-scheme: dark) {
  .navbar {
    background: rgba(43, 48, 53, 0.7) !important;
    z-index: 2;
    backdrop-filter: blur(20px) saturate(180%);
    -webkit-backdrop-filter: blur(20px) saturate(180%);
  }

  .btn {
    border-style: solid;
    border-color: white;
  }

  body {
    background-color: rgb(52, 58, 64);
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}

/* Remove border from toggler */
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

.toggler-icon{
  width: 22px;
  height: 3px;
  background-color: #777777;
  display: block;
  transition: all 0.3s;
}

.middle-bar{
  margin: 3px auto;
}

.navbar-toggler .top-bar {
  transform:  translateY(-1.4142px) rotate(-45deg);
  width: 22px;
  border-radius: 5px;
  transform-origin: 100% 0;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  width: 22px;
  border-radius: 5px;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  transform:  translateY(+1.4142px) rotate(45deg);
  width: 22px;
  border-radius: 5px;
  transform-origin: 100% 100%;
}
/* State when navbar is opened (END) */

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .toggler-icon {
  background-color: #777777;
}

</style>
