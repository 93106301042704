<template>
  <div class="p-5 bg-body-tertiary"></div>
  <section class="pt-5 flex-grow-1 bg-body-tertiary">
    <div class="container">
      <h1 class="display-1 text-center">
        {{ $t("main.contacts.contactMe") }}
      </h1>

      <div class="p-5 bg-body-tertiary"></div>

      <div class = "row text-center">
        <h2>{{$t("main.contacts.canFind")}}</h2>
          <div class="py-4 px-4">
          <a type="button" class="btn btn-primary py-2" href="https://linkedin.com/in/gianlucabergamini" style="background-color: rgb(0, 130, 202);">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16" style="margin-right: 5px !important; margin-bottom: 3px !important;">
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
            </svg>
            Gianluca Bergamini
          </a>
        </div>
      </div>
    </div>
    <div class="p-4 bg-body-tertiary"></div>
  </section>
</template>

<style scoped>

</style>