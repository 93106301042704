import { createApp } from 'vue'
import App from './App.vue'
import Router from './router'

import { createI18n } from "vue-i18n";
import messages from './lang'

const supportedLanguages = [
    {sym: "en", name: "English"},
    {sym: "it", name: "Italiano"}
];

const i18n = createI18n({
    locale: navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages
    }
)

createApp(App).use(Router).use(i18n).mount('#app')

export {supportedLanguages};
export default i18n
