<template>
  <div class="d-flex align-items-center flex-grow-1 justify-content-center text-center bg-body-tertiary">
    <h1 class="text-muted px-3">
      <span id = "english-text" class = "fade-in"> {{$t("main.notFound")}}</span>
    </h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes fadeIn {
  0%   {opacity:0}
  100% {opacity:1}
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 3s;
}
</style>