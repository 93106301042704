// src/router.js

import {createRouter, createWebHistory} from "vue-router";
import About from "@/components/About";
import Projects from "@/components/Projects";
import RouterView from "@/components/Router";
import Home from "@/components/Home"
import NotFound from "@/components/NotFound";

import i18n from "./main.js";
import {supportedLanguages} from "./main.js";
// import UnderConstruction from "@/components/UnderConstruction";
import Contacts from "@/components/ContactsPage.vue";

const router =  createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    routes: [
        {
            path: "/:lang",
            component: RouterView,
            beforeEnter(to, from, next) {
                const lang = to.params.lang;
                if (!supportedLanguages.map(function(lang){return lang.sym}).includes(lang)) return next(supportedLanguages[0].sym + "/not-found");

                i18n.global.locale = lang;

                return next();
            },
            children: [
                {
                    path: "",
                    name: "home",
                    component: Home,
                    meta: {
                        title: 'main.nav.home',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'router.home.description'
                            },
                            {
                                property: 'og:description',
                                content: 'router.home.description'
                            }
                        ]
                    },
                },
                {
                    path: "about",
                    name: "about",
                    component: About,
                    meta: {
                        title: 'main.nav.about',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'router.about.description'
                            },
                            {
                                property: 'og:description',
                                content: 'router.about.description'
                            }
                        ]
                    },
                },
                {
                    path: "contacts",
                    name: "contacts",
                    component: Contacts,
                    meta: {
                        title: 'main.nav.contacts',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'router.contacts.description'
                            },
                            {
                                property: 'og:description',
                                content: 'router.contacts.description'
                            }
                        ]
                    },
                },
                {
                    path: "portfolio",
                    name: "portfolio",
                    component: Projects,
                    meta: {
                        title: 'main.nav.projects',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'router.projects.description'
                            },
                            {
                                property: 'og:description',
                                content: 'router.projects.description'
                            }
                        ]
                    },
                },
                {
                    path: '/:lang/:pathMatch(.*)*',
                    name: "not-found",
                    component: NotFound,
                    meta: {
                        title: 'router.notFound.title'
                    },
                },
            ]
        },
        {
            path: '/', redirect: () => {
                const lang = navigator.language.split('-')[0]
                const newLang = (!supportedLanguages.map(function(lang){return lang.sym}).includes(lang)) ? "en" : lang
                return {
                    path: '/' + newLang,
                }
            }
        },

    ]
});

router.beforeEach((to, from, next) => {
    window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    const lang = to.params.lang;
    if (!supportedLanguages.map(function(lang){return lang.sym}).includes(lang)) return next(supportedLanguages[0].sym + "/not-found");

    i18n.global.locale = lang;

    document.documentElement.setAttribute("lang" , lang);

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
        document.title = i18n.global.t(nearestWithTitle.meta.title) + " - Gianluca Bergamini";
    } else if(previousNearestWithMeta) {
        document.title = i18n.global.t(previousNearestWithMeta.meta.title) + " - Gianluca Bergamini";
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            if (key === "content") {
                tag.setAttribute(key, i18n.global.t(tagDef[key]));
            } else {
                tag.setAttribute(key, tagDef[key]);
            }
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router